<template>
  <div class="login">
    <div class="login__box">
      <div class="login__logo"></div>
      <a-form
        class="login__form"
        ref="refForm"
        :model="formModel"
        :rules="formRules"
        @submit="login"
      >
        <a-form-item has-feedback name="pesel">
          <a-input
            placeholder="PESEL"
            autocomplete="off"
            v-model:value="formModel.pesel"
          />
        </a-form-item>
        <a-form-item has-feedback name="phoneNumber">
          <a-input
            autocomplete="off"
            placeholder="Numer telefonu"
            v-model:value="formModel.phoneNumber"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            class="login__submit"
            type="primary"
            :loading="processingToTheNextPage"
            html-type="submit"
            :disabled="!valid"
          >
            Zaloguj się
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      valid: false,
      processingToTheNextPage: false,
      formModel: {
        pesel: "",
        phoneNumber: "",
      },
      formRules: {
        pesel: [
          {
            required: true,
            message: "Pole pesel jest wymagane",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: "Pole numer telefonu jest wymagane",
          },
          {
            pattern: new RegExp("^\\d{9}$"),
            message: "Podaj prawidłowy numer telefonu",
          },
        ],
      },
    };
  },
  watch: {
    formModel: {
      async handler() {
        this.$refs.refForm
          .validate()
          .then(() => {
            this.valid = true;
          })
          .catch(() => {
            this.valid = false;
          });
      },
      deep: true,
    },
  },
  methods: {
    login() {
      this.processingToTheNextPage = true;
      this.$http
        .post("patient-portal/api/sms-login", {
          phoneNumber: "+48" + this.formModel.phoneNumber,
          pesel: this.formModel.pesel,
        })
        .then(() => {
          this.processingToTheNextPage = false;
          this.$router.push({
            name: "Verify",
            params: {
              redirectedFrom: this.$route.query.redirectedFrom,
              pesel: this.formModel.pesel,
              phoneNumber: "+48" + this.formModel.phoneNumber,
            },
          });
        })
        .catch(() => {
          this.processingToTheNextPage = false;
          this.$notification.open({
            message: "Wystąpił błąd podczas logowania",
            class: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$login-box-width: 450px;

.login {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: inherit;
  .login__box {
    max-width: $login-box-width;
    background-color: white;
    padding: 0 80px;
    width: 100%;

    margin: 15vh 0;

    @media (max-width: $login-box-width) {
      padding: 10px 16px 24px 16px;
      min-height: inherit;
      margin: 0;
    }

    .login__logo {
      margin: 50px auto;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 112px;
      @if $mode == GENERAL {
        background-image: url("~@/assets/images/logo-homedoctor-color.png");
      } @else if $mode == PZU {
        background-image: url("~@/assets/images/logo-pzu-zdrowie-vertical.png");
      } @else if $mode == POLMED {
        background-image: url("~@/assets/images/logo-polmed.png");
      } @else if $mode == MONDIAL {
        background-image: url("~@/assets/images/logo-mondial.png");
      } @else if $mode == ALLIANZ {
        background-image: url("~@/assets/images/logo-allianz.png");
      } @else if $mode == EUROP_ASSISTANCE {
        background-image: url("~@/assets/images/logo-europ-assistance.png");
      } @else if $mode == CMP {
        background-image: url("~@/assets/images/logo-cmp.png");
      } @else if $mode == GLOBAL_ASSISTANCE {
        background-image: url("~@/assets/images/logo-global-assistance.png");
      } @else if $mode == SIGNAL_IDUNA {
        background-image: url("~@/assets/images/logo-signal-iduna.png");
      } @else if $mode == COMPENSA {
        background-image: url("~@/assets/images/logo-compensa.png");
      } @else if $mode == SALTUS {
        background-image: url("~@/assets/images/logo-saltus.png");
      } @else if $mode == TU_ZDROWIE {
        background-image: url("~@/assets/images/logo-tu-zdrowie.png");
      } @else if $mode == AXA {
        background-image: url("~@/assets/images/logo-axa.png");
      } @else if $mode == GENERALI {
        background-image: url("~@/assets/images/logo-generali.png");
      } @else if $mode == APRIL {
        background-image: url("~@/assets/images/logo-april.png");
      } @else if $mode == WARTA {
        background-image: url("~@/assets/images/logo-warta.png");
      }
    }
    .login__form {
      .login__submit {
        margin-top: 40px;
        margin-bottom: 16px;
        width: 100%;
        height: 40px;
      }
    }
  }
}
</style>
